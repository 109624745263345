import styled, { css } from 'styled-components';
// components
import SearchInput from 'components/molecules/headers/SearchInput';
import FillButton from 'cds/buttons/Fill';
import Icon from 'cds/icons';
import BaseHeader, { HeaderBlank } from 'components/layout/headers/Base';
// styles
import { palette, mediaQuery, onlyHover } from 'cds/styles';

/** 메인 헤더 */
const SignPCHeader = ({ ...props }) => {
  const LeftSide = (
    <LeftSideLayout>
      <a href="/">
        <IconWrapper>
          <Icon name="logo_white" width={110} height={20} />
          <Icon name="logo_symbol" width={12} height={13} />
        </IconWrapper>
      </a>
    </LeftSideLayout>
  );

  const RightSide = (
    <RightSideLayout>
      <a href={`${process.env.NEXT_PUBLIC_DEFAULT_HOST}/class/open`}>
        <ClassOpenButton size="32px" color={palette.grey020}>
          클래스개설
        </ClassOpenButton>
      </a>
    </RightSideLayout>
  );

  return (
    <>
      <BaseHeader {...props}>
        <ContentLayout>
          <Item>{LeftSide}</Item>

          <SearchInput />

          <Item>{RightSide}</Item>
        </ContentLayout>
      </BaseHeader>
      <HeaderBlank />
    </>
  );
};

const ContentLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  padding: 0 48px 0 36px;
  margin: auto;
`;

const Item = styled.div`
  flex-grow: 1;
  width: 50px;

  &:first-child {
    display: flex;
    justify-content: flex-start;
  }

  &:last-child {
    display: flex;
    justify-content: flex-end;
  }
`;

const LeftSideLayout = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0;

  & > a {
    display: block;
  }
`;

const RightSideLayout = styled.div`
  flex-grow: 1;
  display: none;
  position: relative;

  ${mediaQuery.large} {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  & > button,
  & > a:not(:first-child) {
    margin-left: 8px;
  }

  ${mediaQuery.large} {
    & > button,
    & > a:not(:first-child) {
      margin-left: 12px;
    }
  }
`;

const ClassOpenButton = styled(FillButton)`
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  margin-right: 4px;
  font-weight: 500;
  font-size: 14px;
  color: ${palette.grey50};
  background-color: ${palette.BTN.BG};

  ${onlyHover} {
    &:hover {
      ${({ disabled }) =>
        !disabled &&
        css`
          &::before {
            background-color: rgba(0, 0, 0, 0.04);
          }
        `};
    }
  }

  &:active {
    ${({ disabled }) =>
      !disabled &&
      css`
        &::before {
          background-color: rgba(0, 0, 0, 0.04);
        }
      `};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 2px;
`;

export default SignPCHeader;
