import Link from 'next/link';
import styled from 'styled-components';
// components
import Icon from 'cds/icons';
import IconButton from 'cds/buttons/Icon';
import FillButton from 'cds/buttons/Fill';
// styles
import * as typography from 'cds/typography';
import { palette, zIndex } from 'cds/styles';

/** 메인단 검색포함 헤더 */
const SigninMobileHeader = () => {
  return (
    <>
      <Wrapper>
        <Header>
          <Inner>
            <Logo>
              <a href="/">
                <IconWrapper>
                  <Icon name="logo_white" width={110} height={20} />
                  <Icon name="logo_symbol" width={12} height={13} />
                </IconWrapper>
              </a>
            </Logo>
            <Service>
              <Link href={`${process.env.NEXT_PUBLIC_DEFAULT_HOST}/search?keyword=`} passHref>
                <SearchIcon as="a">
                  <Icon name="ic_search" width={20} height={20} fill={palette.grey50} />
                </SearchIcon>
              </Link>
              <Link href={`${process.env.NEXT_PUBLIC_DEFAULT_HOST}/class/open`} passHref>
                <ClassApplyButton as="a" size="32px" color={palette.BTN.BG}>
                  클래스 개설
                </ClassApplyButton>
              </Link>
            </Service>
          </Inner>
        </Header>
        <div />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.header`
  height: 56px;

  & > div:last-of-type {
    height: inherit;
  }
`;

const Header = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  height: inherit;
  border-bottom: 1px solid ${palette.system.grey};
  z-index: ${zIndex.header};
  box-sizing: content-box;
`;

const Inner = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  top: 0;
  right: 0;
  left: 0;
  height: inherit;
  padding: 0 16px;
  background-color: ${palette.system.BNB};
  z-index: ${zIndex.header};
`;

const Logo = styled.div`
  flex: 0 auto;
  font-size: 0;

  & > a {
    display: block;
  }
`;

const SearchIcon = styled(IconButton)`
  overflow: hidden;
  flex: 0 0 auto;
  width: 32px;
  height: 32px;
  margin-left: 12px !important;
  background-color: ${palette.BTN.BG};
  border-radius: 100%;
`;

const ClassApplyButton = styled(FillButton)`
  padding: 0 12px;
  ${typography.subtitle4_medium}
  color: ${palette.grey50};
`;

const Service = styled.div`
  display: flex;
  align-items: center;
  flex: 0 auto;

  & > * ~ * {
    margin-left: 12px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 2px;
`;

export default SigninMobileHeader;
